import React from "react";
import Styled, { keyframes } from "styled-components";
import Shape1 from "../../assets/WHITE.png";

import "./styles.css";
export default function ShapeAnimationn(props) {
  const { ln } = props;
  return (
    <Wrapper>
      <img src={Shape1} className="w-100" />
    </Wrapper>
  );
}

const animation = keyframes`
0%{transform:translateX(-100px);}
100%{transform:translateX(0);}
 `;

const Wrapper = Styled.span`
display:inline-block;
animation-name:${animation};
animation-duration:4.3s;
animation-fill-mode:forwards;
animation-iteration-count:infinite
`;
