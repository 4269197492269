import React, { useEffect } from "react";
import { Carousel, Button, Container, Row, Col } from "react-bootstrap";
import Line from "../../assets/line.png";
import useStyles from "./styles";
import Aos from "aos";
import "./styles.css";

const About = (props) => {
  const classes = useStyles();
  const { ln } = props;

  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  return (
    <div
      className="container mt-4 p-5  mb-5"
      id="about"
      data-aos="fade-right"
      data-aos-once="true"
    >
      <center>
        {ln == "en" ? (
          <h1 className="h3b1">ABOUT US</h1>
        ) : (
          <h1 className="hb1 ar1">من نحن؟</h1>
        )}
      </center>
      <center>
        <img src={Line} />
      </center>
      <center>
        {ln == "en" ? (
          <h5 className="h3p1 mb-4 mt-2 en2 text-center">WE ARE ADVERTIQA</h5>
        ) : (
          <h5 className="h3p1 ar2 text-center">نحن ادفرتيكا</h5>
        )}
      </center>

      <center>
        {ln == "en" ? (
          <p className={classes.para}>
            We are an emergent communication, advertising, and marketing
            company. Our spirits resonate with the newfound confidence and pride
            of Qatar as a resilient nation. We extend our services in
            advertising, social media marketing, public relations, event
            management, IT solutions, digital and traditional printing.
            Advertiqa is founded on the extensive experience, and repute of its
            team leader and partners, and built upon the collective expertise
            and creativity of the team behind.
          </p>
        ) : (
          <p className="ar2" dir="rtl">
            نحن شركة ناشئة متخصصة في عمليات التواصل والإتصال الإعلامي والنشاطات
            الإعلانية والتسويق. تنبع مثابرتنا وحماسُنا من ثقتنا وفخرنا بدولة قطر
            وتميزها بأعلى مستويات المرونة والديناميكية على مستوى المنطقة. تشمل
            خدماتنا مجالات الإعلانات والإشهارات والتسويق على شبكات التواصل
            الإجتماعي والعلاقات العامة وتنظيم وإدارة الفعاليات وتقديم حلول نظم
            المعلومات وعمليات الطباعة والنشر التقليدي والرقمي. تعتمد "ادفرتيكا"
            على خبراتها طويلة الأمد وسمعة قائد طواقم عملها وشركائها، المبنية على
            الحرفية والمصداقية والخبرة الشاملة المتكاملة، ناهيك عن إبداع وابتكار
            أعضاء الفريق الواحد الذي يقف وراءها .
          </p>
        )}
      </center>
    </div>
  );
};

export default About;
