import { makeStyles } from "@material-ui/core/styles";

import useWindowDimensions from "../Window";

const drawerWidth = 0;

export default makeStyles((theme) => ({
  carousel: {
    top: 0,
    position: "relative",
  },
  inquire: {
    backgroundColor: "rgba(0,0,0,0)",
    border: "none",
  },
}));
