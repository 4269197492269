import { makeStyles } from "@material-ui/core/styles";
import LINES from "../../assets/LINES.png";

import useWindowDimensions from "../Window";

const drawerWidth = 0;

export default makeStyles((theme) => ({
  About: {
    backgroundImage: `url(${LINES})`,

    backgroundRepeat: "no-repeat",
  },
  about2: {
    padding: 10,
    margin: 50,
  },
  head1: {
    fontSize: 35,
    fontWeight: "bold",
    color: "#056292",
  },
  head2: {
    color: "#96153A",
  },
  card: { boxShadow: `1px 1px 10px #aaa`, height: 100, margin: 50 },
}));
