import React from "react";
import { Button } from "react-bootstrap";
import "../../Styles/fonts.css";
import { Link } from "react-router-dom";

export default function InquireButton(props) {
  const { ln } = props;
  return (
    <div>
      {ln == "en" ? (
        <a
          href="/#contact"
          variant="outline-light"
          style={{
            borderWidth: 3,
            borderColor: "#056292",
            color: "#056292",
            fontSize: 12,
            borderRadius: 7,
          }}
          className="btn en2"
        >
          DROP QUERY
        </a>
      ) : (
        <a
          href="/#contact"
          variant="outline-light"
          style={{
            borderWidth: 3,
            borderColor: "#056292",
            color: "#056292",
            fontSize: 12,
            borderRadius: 7,
          }}
          className="btn"
        >
          مزيد من المعلومات
        </a>
      )}
    </div>
  );
}
