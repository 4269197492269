import React from "react";
import Styled, { keyframes } from "styled-components";
import Shape1 from "../../assets/shape1.png";
import useWindowDimensions from "../Window";

export default function HeadAnimation(props) {
  const { ln } = props;
  return (
    <div>
      <div style={{ flexDirection: "row" }}>
        <Wrapper>
          <h1
            style={{
              color: "#056292",

              fontSize: useWindowDimensions().width / 30,
            }}
            className="ar1"
          >
            {props.text1}
          </h1>
        </Wrapper>
        <Wrapper2>
          <h1
            style={{
              color: "#056292",

              fontSize: useWindowDimensions().width / 30,
            }}
            className="ar1"
          >
            {props.text2}
          </h1>
        </Wrapper2>
      </div>
    </div>
  );
}

const animation1 = keyframes`
0%{transform:translateY(-100px);}
100%{transform:translateY(0);}
 `;

const animation2 = keyframes`
0%{transform:translateY(100px);}
100%{transform:translateY(0);}
 `;

const Wrapper = Styled.span`
display:inline-block;
animation-name:${animation1};
animation-duration:1s;
animation-fill-mode:forwards;
animation-iteration-count:1
`;

const Wrapper2 = Styled.span`
display:inline-block;
animation-name:${animation2};
animation-duration:1s;
animation-fill-mode:forwards;
animation-iteration-count:1
`;
