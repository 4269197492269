import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Slide1 from "../../assets/MAIN_SLIDER/ENGLISH VERSION/ADVERTISING.jpg";
import Slide2 from "../../assets/MAIN_SLIDER/ENGLISH VERSION/COMMUNICATIONS.jpg";
import Slide3 from "../../assets/MAIN_SLIDER/ENGLISH VERSION/MARKETING.jpg";

import Slide1AR from "../../assets/MAIN_SLIDER/ARABIC VERSION/ADVERTISING_AR.jpg";
import Slide2AR from "../../assets/MAIN_SLIDER/ARABIC VERSION/COMMUNICATIONS_AR.jpg";
import Slide3AR from "../../assets/MAIN_SLIDER/ARABIC VERSION/MARKETING_AR.jpg";

import Shape1 from "../Animated/shape1";
import Shape2 from "../Animated/shape2";

import HeadAnimation from "../Animated/HeadingEN";

import HeadAnimationAR from "../Animated/HeadingAR";
import { Carousel, Button } from "react-bootstrap";
import { ReactComponent as Inquire } from "../../assets/inquire.svg";
import InquireButton from "../../components/Buttons/Inquire";
import useStyles from "./styles";
import "./styles2.css";

const Carousels = (props) => {
  const { ln } = props;

  const classes = useStyles();

  return (
    <div>
      <Carousel interval={0} className={classes.carousel}>
        <Carousel.Item interval={4000}>
          {ln == "en" ? (
            <img className="d-block w-100" src={Slide2} alt="First slide" />
          ) : (
            <img className="d-block w-100" src={Slide2AR} alt="First slide" />
          )}
          {ln == "en" ? (
            <Carousel.Caption style={{ zIndex: 3,left:-3 }}>
              <Row>
                <Col xs={7} md={6} lg={6}>
                  <div className={classes.communication}>
                    <HeadAnimation
                      text1={"COMMUN"}
                      text2={"ICATIONS"}
                      ln={ln}
                    />
                  </div>

                  <InquireButton ln={ln} />
                </Col>
                <Col></Col>
              </Row>
            </Carousel.Caption>
          ) : (
            <Carousel.Caption style={{ zIndex: 3,left:-3 }}>
              <Row>
                <Col></Col>
                <Col xs={7} md={6} lg={6}>
                  <div className={classes.communication}>
                    <HeadAnimationAR text2={"التو"} text1={"اصل"} ln={ln} />
                  </div>

                  <InquireButton ln={ln} />
                </Col>
              </Row>
            </Carousel.Caption>
          )}
        </Carousel.Item>
        <Carousel.Item interval={4000}>
          {ln == "en" ? (
            <img className="d-block w-100" src={Slide1} alt="First slide" />
          ) : (
            <img className="d-block w-100" src={Slide1AR} alt="First slide" />
          )}
          {ln == "en" ? (
            <Carousel.Caption style={{ zIndex: 3,left:-3 }}>
              <Row>
                <Col xs={7} md={6} lg={6}>
                  <h1 className={classes.communication}>
                    <HeadAnimation text1={"ADVER"} text2={"TISING"} />
                  </h1>

                  <InquireButton ln={ln} />
                </Col>
                <Col></Col>
              </Row>
            </Carousel.Caption>
          ) : (
            <Carousel.Caption style={{ zIndex: 3,left:-3 }}>
              <Row>
                <Col></Col>
                <Col xs={7} md={6} lg={6}>
                  <h1 className={classes.communication}>
                    <HeadAnimationAR text1={"علان"} text2={"الإ"} />
                  </h1>

                  <InquireButton ln={ln} />
                </Col>
              </Row>
            </Carousel.Caption>
          )}
        </Carousel.Item>
        <Carousel.Item interval={4000}>
          {ln == "en" ? (
            <img className="d-block w-100" src={Slide3} alt="First slide" />
          ) : (
            <img className="d-block w-100" src={Slide3AR} alt="First slide" />
          )}
          {ln == "en" ? (
            <Carousel.Caption style={{ zIndex: 3,left:-3 }}>
              <Row>
                <Col xs={7} md={6} lg={6}>
                  <h1 className={classes.communication}>
                    <HeadAnimation text1={"MARKE"} text2={"TING"} />
                  </h1>

                  <InquireButton ln={ln} />
                </Col>
                <Col></Col>
              </Row>
            </Carousel.Caption>
          ) : (
            <Carousel.Caption style={{ zIndex: 3,left:-3 }}>
              <Row>
                <Col></Col>
                <Col xs={7} md={6} lg={6}>
                  <h1 className={classes.communication}>
                    <HeadAnimationAR text1={"يق"} text2={"التسو"} />
                  </h1>

                  <InquireButton ln={ln} />
                </Col>
              </Row>
            </Carousel.Caption>
          )}
        </Carousel.Item>
      </Carousel>

      {ln == "en" ? (
        <div className="overlay top">
          <Shape1 />
        </div>
      ) : (
        <div className="overlay2 top">
          <Shape1 />
        </div>
      )}

      {ln == "en" ? (
        <div className="overlay bot">
          <Shape2 ln={ln} />
        </div>
      ) : (
        <div className="overlay2 bot">
          <Shape2 ln={ln} />
        </div>
      )}
    </div>
  );
};

export default Carousels;
